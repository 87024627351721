<template>
  <div class="simpleUploadFileAllCommit">
    <simple-dialog ref="fileUpload" title="上传文件" @handleClose="handleClose">
      <el-form ref="form" :model="uploadFile" label-width="80px" class="row">
        <slot name="before" />
        <el-form-item prop="uploadFile" label="文件">
          <el-upload ref="upload"
                     v-bind="$attrs"
                     :action="action"
                     :on-exceed="handleExceed"
                     :on-error="uploadError"
                     :file-list="fileList"
                     :on-change="onChange"
                     :on-success="uploadSuccess"
                     :http-request="requestFile"
                     :accept="accept"
                     :list-type="listType"
          >
            <el-button size="small" type="primary">
              点击上传
            </el-button>
            <template slot="file" slot-scope="{file}">
              <template v-if="listType==='picture-card'">
                <img :id="'file'+file.index" class="el-upload-list__item-thumbnail" :src="file.status==='success'?file.url+'&isCompress=true':file.url" alt="">
                <span class="el-upload-list__item-actions" @click.self="handlePictureCardPreview(file)">
                  <span v-if="!disabled && file.status !== 'ready'" class="el-upload-list__item-preview" @click.prevent="handlePictureOriginPreview(file)">
                    <i class="el-icon-zoom-in" />
                  </span>
                  <span v-if="!disabled && file.status !== 'ready'" class="el-upload-list__item-delete">
                    <a style="text-decoration: none;outline: none;color: #fff;" :href="file.url+'&isCompress=false'"><i class="el-icon-download" /></a>
                  </span>
                  <span v-if="!disabled && file.status !== 'ready'" class="el-upload-list__item-delete" @click.prevent="handleRemove(file)">
                    <i class="el-icon-delete" />
                  </span>
                </span>
              </template>
              <template v-else>
                <div class="el-upload-list__item is-success">
                  <a class="el-upload-list__item-name" :href="file.url">{{ file.name }}</a>
                  <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-circle-check" /></label>
                  <i class="el-icon-close" @click="handleRemove(file)" />
                </div>
              </template>
            </template>
          </el-upload>
        </el-form-item>
        <slot name="after" />
      </el-form>
      <div class="NumFooter">
        <el-button v-if="showDownload">
          <a :href="allFileAction" target="_blank">全部下载</a>
        </el-button>
        <el-button @click.native="()=>{$refs.fileUpload.close();handleClose()}">
          取消
        </el-button>
        <el-button :loading="$store.state.isLoading" type="primary" @click.native="upload">
          确认
        </el-button>
      </div>
    </simple-dialog>
    <ImageView v-if="listType==='picture-card'&&showView"
               :on-close="()=>showView=false" :z-index="10000" :initial-index="viewIndex"
               :url-list="listFile"
    />
    <ImageView v-if="showOrigin"
               :on-close="()=>showOrigin=false" :z-index="10000" :initial-index="0"
               :url-list="listOriginFile"
    />
  </div>
</template>

<script>
import SimpleDialog from '@/components/simple/SimpleDialog'
import { Message } from 'element-ui'
import ArrayUtils from '@/common/js/ArrayUtils'
import { getBase64Image } from '@/common/js/ImgUtils'

export default {
  name: 'SimpleUploadFileAllCommit',
  components: { SimpleDialog },
  props: {
    action: { type: String, required: true },
    allFileAction: { type: String, required: false, default: null },
    removeUrl: { type: String, required: true },
    accept: { type: String, required: false, default: 'application/all' },
    listType: { type: String, required: false, default: 'text', validator: (value) => { return ['text', 'picture', 'picture-card'].includes(value) } },
  },
  data() {
    return {
      uploadFile: { uploadFile: null },
      uploadFileRule: { uploadFile: [{ required: true, message: '请选择文件', trigger: 'blur' }] },
      fileData: new FormData(),
      fileList: [],
      dialogImageUrl: null,
      disabled: false,
      showView: false,
      viewIndex: 0,
      showOrigin: false,
      listOriginFile: null,
    }
  },
  computed: {
    showDownload() {
      return this.allFileAction && this.fileList.length > 0 && this.fileList.filter(file => file.status !== 'ready').length > 1
    },
    listFile() {
      return this.fileList.map(item => getBase64Image(document.getElementById('file' + item.index)))
    }
  },
  watch: {
    fileList: {
      immediate: true,
      handler(fileList) {
        this.uploadFile.uploadFile = fileList
      }
    }
  },
  methods: {
    visible() {
      this.$refs.fileUpload.changeVisible()
    },
    uploadError() {
      this.$message.warning('上传失败')
    },
    uploadSuccess(response, file, fileList) {
      if (!response.success) {
        const msg = response.msg || '未知错误'
        Message({
          message: msg,
          type: 'error'
        })
        return
      }
      this.$refs.upload.clearFiles()
      this.$refs.fileUpload.close()
      this.$emit('change')
    },
    requestFile(file) {
      this.fileData.append('files', file.file) // 服务器最终接收到的是files[]数组
    },
    upload() {
      this.$refs.form.validate((item, b) => {
        if (item) {
          const temp = this.$refs.upload.uploadFiles.filter(file => file.status === 'ready')
          if (!temp || temp.length === 0) {
            this.$refs.upload.clearFiles()
            this.$refs.fileUpload.close()
            return
          }
          this.$post(this.action, this.fileData).then(() => {
            this.$refs.fileUpload.close()
            this.fileList = []
            this.fileData = new FormData()
            this.$emit('commit')
          })
        }
      })
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.$attrs.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    onChange(file, fileList) {
      this.uploadFile.uploadFile = file
    },
    handleRemove(file) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post(this.removeUrl, {
          path: file.fileName,
        }).then(() => {
          this.fileList.splice(ArrayUtils.getArrayObject(this.fileList, 'uid', file.uid).$index, 1)
          this.$emit('change')
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    handlePictureOriginPreview(file) {
      if (file.status === 'success') {
        this.showOrigin = true
        this.listOriginFile = [file.url + '&isCompress=false']
      }
    },
    handlePictureCardPreview(file) {
      if (file.status === 'success') {
        this.showView = true
        this.viewIndex = file.index
      }
    },
    handleClose() {
      this.$refs.upload.clearFiles()
      this.fileData = new FormData()
    },
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color:#606266;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
</style>
