<template>
  <div class="Inspect">
    <SimpleCURD
      ref="inspect"
      :is-search="false"
      search-url="/api/inspect/list"
      add-or-update-url="/api/inspect/addOrUpdate"
      delete-url="/api/inspect/delete"
      :show-form="showForm"
      :model="addForm"
      :add-form-rules="addFormRules"
      is-pageable
      label-width="160px" operation-width="250px"
      :format-commit-data="formatCommitData"
      :show-add-button="showAddButton"
      :show-edit-button="showAddButton"
      :show-delete-button="showAddButton"
      :only-read="()=>!showAddButton()"
      @beforeAddOrUpdate="beforeAddOrUpdate"
      @add="add"
      @search="getProjectToDevice"
    >
      <template #searchOperation>
        <el-button type="primary" plain size="medium" @click="handleUploadFile('inspectDocument')">
          查勘附件
        </el-button>
        <el-button v-show="showAddButton()" type="primary" plain size="medium" @click="inspectFinish">
          查勘完成
        </el-button>
        <el-checkbox v-if="showAddButton" v-model="isSaveLocation">
          提交表单时保存点位
        </el-checkbox>
      </template>
      <template #operation="{row}">
        <el-button v-if="showAddButton()" type="primary" size="small" @click="handleUploadFile('img',row,'picture-card')">
          图片
        </el-button>
        <el-button v-if="!showAddButton()" type="primary" size="small" @click="$refs.inspect.handleEdit(row)">
          查看
        </el-button>
      </template>
    </SimpleCURD>

    <SimpleUploadFileAllCommit ref="uploadFile"
                               multiple
                               :limit="limitFile"
                               :action="actionFile"
                               :remove-url="removeUrlFile"
                               :all-file-action="allFileAction"
                               accept="*/*"
                               @change="success"
                               @commit="success"
    >
      <template #before>
        <el-form-item label="项目名">
          <el-input v-model="getProjectName" disabled />
        </el-form-item>
      </template>
    </SimpleUploadFileAllCommit>
    <SimpleUploadFileAllCommit ref="uploadImg"
                               multiple
                               :remove-url="removeUrlImg"
                               :limit="limitImg"
                               :action="actionImg"
                               :all-file-action="allImgAction"
                               list-type="picture-card"
                               accept="image/jpeg,image/gif,image/png"
                               @change="$refs.inspect.search()"
                               @commit="$refs.inspect.search()"
    />
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
import ArrayUtils from '@/common/js/ArrayUtils'
import util from '@/common/js/util'
import SimpleUploadFileAllCommit from '@/components/simple/SimpleUploadFileAllCommit'

export default {
  name: 'Inspect',
  components: { SimpleUploadFileAllCommit, SimpleCURD },
  data() {
    const notshow = (type, data) => !data.inspectDeviceId || data.inspectDeviceId !== 1
    const locationScopeShow = (type, data) => !data.inspectDeviceId || data.inspectDeviceId !== 2
    const checkFireControlHost = (message, param) => {
      const temp = ArrayUtils.getArrayObject(this.device, 'id', this.addForm.inspectDeviceId)?.id
      if (temp === 1 && !param[1]) {
        param[2](new Error(message))
      }
      param[2]()
    }
    return {
      // inspect start
      addForm: {
        projectId: null,
        location: null,
        imagePath: null,
        serialNo: null,
        inspectDeviceId: null,
        infoTransmitLocation: null,
        brand: null,
        model: null,
        g4: null,
        nb: null,
        connectionMode: null,
        network: null,
        havePowerSupply: null,
        filePath: null,
        description: null,
        watchLocation: null,
        locationScope: null
      },
      showForm: [
        {
          param: 'projectId',
          disabled: (type) => type !== 'search' && !this.showAddButton(),
          name: '项目名称',
          search: true,
          type: 'select',
          hidden: true,
          filterable: true,
          option: () => this.user().projectList.filter(item => {
            return item.stage.ordinal <= 0
          }).map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          }),
          formatter: (row) => {
            let name = ''
            this.user().projectList.forEach(project => {
              if (project.id === row.projectId) {
                name = project.name
              }
            })
            return name
          },
          clearable: true,
        },
        {
          param: 'stage',
          name: '阶段',
          search: true,
          type: 'select',
          width: '80px',
          disabled: (type) => type !== 'search' && !this.showAddButton(),
          clearable: true,
          notShow: true,
          option: () => [{ label: '未施工', value: 0 }, { label: '施工完毕', value: 1 }],
          formatter: (row) => {
            switch (row.stage) {
            case 0:return <span>未施工</span>
            case 1:return <span>施工完毕</span>
            default:return <span></span>
            }
          },
        },
        {
          param: 'inspectDeviceId',
          name: '设备类型',
          disabled: (type) => type !== 'search' && !this.showAddButton(),
          search: true,
          type: 'select',
          clearable: true,
          width: '180px',
          option: () => this.device.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          }),
          formatter: (row) => {
            const d = ArrayUtils.getArrayObject(this.device, 'id', row.inspectDeviceId)
            return d ? <span>{d.name}</span> : <span>未定义</span>
          },
        },
        { param: 'locationScope', name: '生成楼层范围', hidden: true, maxlength: '100', clearable: true, notShow: locationScopeShow, help: () => '此输入框按格式填写会生成多条数据，不填写只生成一条数据。格式：数字~数组 注意：其中没有空格 例: 1~10' },
        { param: 'location', name: '点位信息', search: true, disabled: (type) => type !== 'search' && !this.showAddButton(), maxlength: '100', clearable: true, help: () => '批量新增对需要修改的地方使用%s代替，例：xxx园区%s楼末端' },
        { param: 'watchLocation', name: '监控位置', search: true, disabled: (type) => type !== 'search' && !this.showAddButton(), maxlength: '100', clearable: true, help: () => '批量新增对需要修改的地方使用%s代替，例：xxx园区%s楼末端' },
        {
          param: 'havePowerSupply',
          name: '是否有电源',
          type: 'radio',
          width: '100px',
          disabled: () => !this.showAddButton(),
          clearable: true,
          radio: [{ label: '有', value: 0 }, { label: '无', value: 1 }],
          formatter: (row) => {
            switch (row.havePowerSupply) {
            case 0:return <el-tag type="success">有电源</el-tag>
            case 1:return <el-tag type="info">无电源</el-tag>
            default:return <el-tag type="info">未定义</el-tag>
            }
          },
        },
        {
          param: 'g4',
          name: '4G信号强度(移动)',
          width: '150px',
          disabled: () => !this.showAddButton(),
          type: 'select',
          hidden: true,
          option: [{ value: 3, label: '好' }, { value: 4, label: '中' }, { value: 5, label: '差' }, { value: 6, label: '无' }],
        },
        {
          param: 'nb',
          name: 'NB信号强度(移动)',
          width: '150px',
          disabled: () => !this.showAddButton(),
          type: 'select',
          hidden: true,
          option: [{ value: 3, label: '好' }, { value: 4, label: '中' }, { value: 5, label: '差' }, { value: 6, label: '无' }],
        },
        {
          param: 'signal',
          name: '信号强度(移动)',
          width: '120px',
          notShow: () => true,
          formatter: (row) => <div>
            {row.g4 ? <div>4G:<span>{ArrayUtils.getArrayObject(this.netMap, 'value', row.g4).label}</span></div> : null}
            {row.nb ? <div>NB:<span>{ArrayUtils.getArrayObject(this.netMap, 'value', row.nb).label}</span></div> : null}
          </div>
        },
        { param: 'brand', name: '主机品牌', disabled: () => !this.showAddButton(), clearable: true, hidden: true, maxlength: '100', notShow: notshow },
        { param: 'model', name: '主机型号', disabled: () => !this.showAddButton(), clearable: true, hidden: true, maxlength: '100', notShow: notshow },
        { param: 'connectionMode', name: '连接方式', disabled: () => !this.showAddButton(), clearable: true, hidden: true, maxlength: '100', notShow: notshow },
        {
          param: 'createTime',
          name: '查勘时间',
          width: '100px',
          disabled: () => !this.showAddButton(),
          type: 'date',
          date: 'date',
          notShow: true,
          formatter: (row) => {
            return util.formatDate.format(row.createTime, 'yyyy-MM-dd')
          }
        },
        { param: 'description', name: '备注', disabled: () => !this.showAddButton(), clearable: true, hidden: true, maxlength: '1000' },
      ],
      addFormRules: {
        projectId: [{ required: true, message: '请选择项目名称', trigger: 'blur' }],
        location: [{ required: true, message: '请输入点位信息', trigger: 'blur' }],
        inspectDeviceId: [{ required: true, message: '请输入设备类型', trigger: 'blur' }],
        havePowerSupply: [{ required: true, message: '请选择电源', trigger: 'blur' }],
        g4: [{ required: true, message: '4G信号强度', trigger: 'blur' }],
        nb: [{ required: true, message: 'NG信号强度', trigger: 'blur' }],
        brand: [{ validator: (...param) => checkFireControlHost('请输入消控主机的品牌信息', param) }],
        model: [{ validator: (...param) => checkFireControlHost('请输入消控主机的型号信息', param) }],
        connectionMode: [{ validator: (...param) => checkFireControlHost('请输入消控主机的连接方式', param) }],
      },
      // inspect end
      // file start
      showButton: true,
      limitFile: null,
      actionFile: '',
      removeUrlImg: '',
      limitImg: null,
      actionImg: '',
      allFileAction: '',
      allImgAction: '',
      // file end
      stages: [],
      removeUrlFile: '',
      device: [],
      data: [],
      isSaveLocation: true,
      location: null
    }
  },
  inject: ['user', 'reFlushSession', 'netMap', 'getStage', 'getDevice'],
  computed: {
    getProjectName() {
      return ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.inspect.searchAddForm.projectId)?.name
    },
  },
  mounted() {
    this.$watch(() => this.$refs.inspect.searchAddForm.projectId, () => {
      this.$refs.inspect.search()
      this.showButton = this.changeShowButton()
    })
    this.user().projectList.filter(item => {
      return item.stage.ordinal <= 0
    })
  },
  created() {
    this.reFlushSession()
    this.getStage()
    this.getDevice()
  },
  methods: {
    // inspect start
    formatCommitData(result) {
      result.forEach(item => {
        item.status = item.status.ordinal
        if (item.network) {
          item.g4 = JSON.parse(item.network)[0]
          item.nb = JSON.parse(item.network)[1]
        }
      })
      return result
    },
    getCurrentProject() {
      return this.$refs.inspect.searchAddForm.projectId ? ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.inspect.searchAddForm.projectId) || null : null
    },
    showAddButton() {
      return ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.inspect?.searchAddForm.projectId)?.stage.ordinal === 0
    },
    add(addForm) {
      addForm.projectId = this.$refs.inspect.searchAddForm.projectId
      addForm.stage = 0
      if (this.isSaveLocation) {
        addForm.location = this.location
      }
    },
    getProjectToDevice(data) {
      this.data = data
    },
    inspectFinish() {
      const project = ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.inspect.searchAddForm.projectId)
      this.$confirm(project?.name + '项目确认查勘完成吗？', '提示').then(() => {
        this.$get('/api/project/updateFinish', {
          projectId: this.$refs.inspect.searchAddForm.projectId,
          stage: 'CONSTRUCTING',
        }).then(() => {
          this.reFlushSession()
        })
      })
    },
    // inspect end
    // show dialog
    handleUploadFileProject(path) {
      this.$refs.uploadFile.visible()
      const project = this.getArrayObject()
      this.actionFile = `/api/project/upload/${path}?id=` + project.id
      this.$refs.inspect.search()
    },
    handleUploadFile(path, row, pic) {
      let fileList = []
      if (path === 'img') {
        if (row.imagePath) {
          fileList = row.imagePath.split(',').map((item, i) => {
            return {
              name: i,
              url: `/api/inspect/download?path=${encodeURI(item)}&projectId=${this.$refs.inspect.searchAddForm.projectId}&flag=inspect`,
              fileName: encodeURI(item),
              index: i
            }
          })
        }
        this.$refs.uploadImg.fileList = fileList
        this.removeUrlImg = `/api/inspect/delete/${row.id}/${path}?projectId=${this.$refs.inspect.searchAddForm.projectId}&type=inspect`
        this.actionImg = `/api/inspect/upload/${path}?id=${row.id}&projectId=${this.$refs.inspect.searchAddForm.projectId}&type=inspect`
        this.allImgAction = `/api/inspect/download/all?id=${row.id} &projectId=${this.$refs.inspect.searchAddForm.projectId}&type=inspect`
        this.$refs.uploadImg.visible()
      } else {
        this.reFlushSession().then(() => {
          const project = this.getArrayObject()
          if (!project) {
            this.$OneMessage.warning('暂无查勘项目')
            return
          }
          if (project.inspectDocumentPath) {
            fileList = project.inspectDocumentPath.split(',').map((item, i) => {
              return {
                name: `${item}`,
                url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=false&projectId=${this.$refs.inspect.searchAddForm.projectId}&type=${path}`,
                fileName: encodeURI(item),
                index: i,
              }
            })
          }
          this.$refs.uploadFile.fileList = fileList
          this.removeUrlFile = `/api/project/delete/${path}/${project.id}`
          this.actionFile = `/api/project/upload/${path}?id=${project.id}`
          this.allFileAction = `/api/project/download/${path}/all?id=${project.id}`
          this.$refs.uploadFile.visible()
        })
      }
    },
    beforeAddOrUpdate() {
      this.location = this.addForm.location
    },
    // hidden dialog
    // file start
    success() {
      this.$refs.inspect.search()
      this.showButton = true
    },
    deleteFileProject(path) {
      this.$confirm('确认删除项目 ' + this.getArrayObject().name + '的附件吗？', '删除').then(() => {
        this.$post(`/api/project/delete/${path}/` + this.getArrayObject().id).then(() => {
          this.$refs.inspect.search()
          this.$forceUpdate()
          this.showButton = false
        })
      })
    },
    // file end
    deleteFile(row) {
      this.$post(`/api/inspect/delete/${row.id}/file`, { path: row.filePath }).then(() => {
        this.$refs.inspect.search()
      })
    },
    showProjectFileUrl() {
      return !this.getArrayObject() ? '#' : '/api/project/download/inspectDocument/' + this.getArrayObject().id
    },
    getArrayObject() {
      return !this.$refs.inspect ? '#' : ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.inspect.searchAddForm.projectId)
    },
    changeShowButton() {
      return this.getArrayObject() ? this.getArrayObject().inspectDocumentPath : false
    },
  },
}

</script>

<style scoped>
a {
  text-decoration: none;
  color: #409EFF;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
  color: #FFF;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
  color: #FFF;
}

.el-icon-delete {
  display: none;
}
.el-checkbox{
  padding-left: 10px;
}

.el-upload-list__item:hover .el-icon-delete {
  position: absolute;
  right: 5px;
  display: block;
}
</style>
