var SIGN_REGEXP = /([yMdhsm])(\1*)/g
var DEFAULT_PATTERN = 'yyyy-MM-dd'
function padding(s, len) {
  len = len - (s + '').length
  for (var i = 0; i < len; i++) { s = '0' + s }
  return s
};

function compile(code) {
  var c = String.fromCharCode(code.charCodeAt(0) + code.length)
  for (var i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1))
  }
  return escape(c)
}

function uncompile(code) {
  code = unescape(code)
  var c = String.fromCharCode(code.charCodeAt(0) - code.length)
  for (var i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1))
  }
  return c
}

export default {

  saveToSession(key, value) {
    if (!key || !value) {
      return
    }
    const eKey = compile(key)
    const eValue = compile(value)
    sessionStorage.setItem(eKey, eValue)
  },
  getFromSession(key) {
    if (!key) {
      return null
    }
    const eKey = compile(key)
    const eValue = sessionStorage.getItem(eKey)
    if (!eValue) {
      return null
    }
    return uncompile(eValue)
  },
  removeSession(key) {
    if (!key) {
      return
    }
    sessionStorage.removeItem(key)
    const eKey = compile(key)
    sessionStorage.removeItem(eKey)
  },

  getQueryStringByName: function(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = window.location.search.substr(1).match(reg)
    var context = ''
    if (r !== null) { context = r[2] }
    reg = null
    r = null
    return context === null || context === '' || context === 'undefined' ? '' : context
  },
  formatDate: {

    format: function(date1, pattern) {
      const date = new Date(date1)
      pattern = pattern || DEFAULT_PATTERN
      return pattern.replace(SIGN_REGEXP, function($0) {
        switch ($0.charAt(0)) {
        case 'y': return padding(date.getFullYear(), $0.length)
        case 'M': return padding(date.getMonth() + 1, $0.length)
        case 'd': return padding(date.getDate(), $0.length)
        case 'w': return date.getDay() + 1
        case 'h': return padding(date.getHours(), $0.length)
        case 'm': return padding(date.getMinutes(), $0.length)
        case 's': return padding(date.getSeconds(), $0.length)
        }
      })
    },
    parse: function(dateString, pattern) {
      var matchs1 = pattern.match(SIGN_REGEXP)
      var matchs2 = dateString.match(/(\d)+/g)
      if (matchs1.length === matchs2.length) {
        var _date = new Date(1970, 0, 1)
        for (var i = 0; i < matchs1.length; i++) {
          var _int = parseInt(matchs2[i])
          var sign = matchs1[i]
          switch (sign.charAt(0)) {
          case 'y': _date.setFullYear(_int); break
          case 'M': _date.setMonth(_int - 1); break
          case 'd': _date.setDate(_int); break
          case 'h': _date.setHours(_int); break
          case 'm': _date.setMinutes(_int); break
          case 's': _date.setSeconds(_int); break
          }
        }
        return _date
      }
      return null
    }
  },
  formatNumber(number) {
    const a = (number + '').split('\\.')
    if (a[0] && a[0] !== 'null') {
      if (!a[1]) {
        return a[0].replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,') + '.00'
      }
      return a[0].replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,') + '.' + a[1]
    } else {
      return '无'
    }
  },

}
